import {AnyAction} from "redux";

const initState: AppState = {
  user: {}
};

export function app(state: AppState = initState, action: AnyAction) {
  switch (action.type) {
    default:
      return {...state};
  }
}

export interface AppState {
  user: {}
}