import request, {BasePageResponse, BaseQueryPageParam, BaseResponse, NumberLike} from "../utils/request";
import {ApiUri} from "../constants/ConfigEnum";

/**
 * 账号登录
 * @param account
 * @param pwd
 */
export function accountLogin(account: string, pwd: string) {
    return request.post<BaseResponse<AccountLoginInfo>>(`${ApiUri.SYSTEM}/accountLogin/loginPc`, {
        account,
        pwd,
    });
}

/**
 * 退出登录
 */
export function accountExitPc() {
    return request.post<BaseResponse<AccountLoginInfo>>(`${ApiUri.SYSTEM}/accountLogin/exitPc`);
}


export function queryDicdateSelect(params: DictParam) {
    return request.get<BaseResponse<DicitemVoListItem[]>>(`${ApiUri.SYSTEM}/dicdate/selectDic`, {params})
}


/**
 * 用户品牌字典查询
 */
export function queryDicdateSelectBrand(pid: NumberLike, companyId?: NumberLike) {
    return request.get<BaseResponse<DicdateItem[]>>(`${ApiUri.SYSTEM}/companyDicdate/select`, {params: {pid, companyId}})
}


/**
 * 上传文件
 * @param data
 */
export function uploadFile(data: FormData, callback: (event: any) => void) {
    return request.post(`${ApiUri.SYSTEM}/ossFile/uploadFile`, data, {
        onUploadProgress: (e) => {
            callback(e);
        }
    })
}

export function osspolicy() {
    return request.get<BaseResponse<OssPolicyResult>>(
        `${ApiUri.SYSTEM}/ossFile/getOssPolicy`
    );
}

/**
 * 地址库查询
 * @param pid
 */
export function queryAddressList(pid: number | string, level: number) {
    return request.get<BaseResponse<AddressListItem[]>>(`${ApiUri.SYSTEM}/address/getAddressList`, {
        params: {
            pid,
            level
        }
    });
}


/**
 * 查询客户工单类型字典
 * @param companyId
 */
export function selectCompanySupportTypeDic(companyId: NumberLike) {
    return request.get<BaseResponse<Array<{ name: string; id: NumberLike; logicId: NumberLike; logicName: string; }>>>(`${ApiUri.SYSTEM}/supportType/select`, {params: {companyId}})
}


/**
 * 查询Excel导入记录
 */
export function queryExcelRecord(params: BaseQueryPageParam<QueryExcelRecordParams>) {
    return request.get<BasePageResponse<ExcelRecordListItem>>(`${ApiUri.SYSTEM}/excelRecord/getList`, {params})
}

/**
 * 差Excel错误记录
 */
export function queryExcelErrorMsg(params: BaseQueryPageParam<QueryExcelErrorMsgParams>) {
    return request.get<BasePageResponse<ExcelErrorMsgListItem>>(`${ApiUri.SYSTEM}/excelErrorMsg/getList`, {params})
}

/**
 * 设备与客户关联档案导入模版下载
 */
export function deviceExcelTemplate() {
    return request.get<BaseResponse<string>>(`${ApiUri.SYSTEM}/deviceConfig/createExcelTemplate`)
}

/**
 * 设备与客户关联批量导入
 * @param formData
 */
export function deviceBatchImport(formData: FormData) {
    return request.post(`${ApiUri.SYSTEM}/deviceConfig/batchImport`, formData);
}

/**
 * 设备与客户关联撤回导入
 * @param recordId
 */
export function deviceRestBatch(recordId: NumberLike) {
    return request.post(`${ApiUri.SYSTEM}/deviceConfig/restBatch`, {recordId});
}


/**
 * 客户和设备辅材关联配置导出
 * @param params
 */
export function deviceAssistConfigExport(params: deviceAssistConfigParams) {
    return request.get<BaseResponse<string>>(`${ApiUri.SYSTEM}/deviceAssistConfig/exportList`, {params, responseType: "arraybuffer"})
}

export function queryDeviceList(params: QueryDeviceListParams) {
    return request.get<BasePageResponse<DeviceListItem>>(`${ApiUri.SYSTEM}/deviceAssist/getList`, {params})
}

/**
 * 辅材申请导出
 * @param params
 */
export function queryDeviceExport(params: QueryDeviceListParams) {
    return request.get<BaseResponse<string>>(`${ApiUri.SYSTEM}/deviceAssist/exportList`, {params})
}

/**
 * 账号下拉框
 * @param params
 */
export function queryAccountSelect(accountType: NumberLike, companyId?: NumberLike) {
    return request.get<BaseResponse<any>>(`${ApiUri.SYSTEM}/account/select`, {
        params: {
            accountType,    // 客户：100101
            companyId
        }
    })
}

/**
 * 字典表类型新增
 * @param pids
 */
export function queryDicdateTypeSave(data: QueryDicdateSaveParams) {
    return request.post(`${ApiUri.SYSTEM}/dicdate/saveType`, data)
}

/**
 * 字典表新增
 */
export function queryDicdateSave(data: DictParam) {
    return request.post(`${ApiUri.SYSTEM}/dicdate/save`, data)
}

/**
 * 字典表修改
 */
export function queryDicdateUpdate(id: NumberLike, data: DictParam) {
    return request.put(`${ApiUri.SYSTEM}/dicdate/update/${id}`, data)
}

/**
 * 字典表删除
 */
export function queryDicdateDelete(ids: string) {
    return request.delete(`${ApiUri.SYSTEM}/dicdate/delete`, {params: {ids}})
}

/**
 * 字典详情
 */
export function dictInfo(id: NumberLike) {
    return request.get(`${ApiUri.SYSTEM}/dicdate/info/${id}`)
}

/**
 * 字典表分页查询
 */
export function queryDicdateList(params: DictParam) {
    return request.get(`${ApiUri.SYSTEM}/dicdate/getDicdateList`, {params})
}

/**
 * 工单字段配置分页查询
 * @param pids
 */
export function WorkOrderList(params: Partial<WorkOrderListParams>) {
    return request.get(`${ApiUri.SYSTEM}/supportFieldConfig/getList`, {params})
}

/**
 * 工单字段配置新增
 * @param pids
 */
export function WorkOrderSave(data: WorkOrderSaveParams) {
    return request.post(`${ApiUri.SYSTEM}/supportFieldConfig/insert`, data)
}

/**
 * 工单字段配置修改
 * @param pids
 */
export function WorkOrderUpdate(id: NumberLike, data: WorkOrderSaveParams) {
    return request.put(`${ApiUri.SYSTEM}/supportFieldConfig/update/${id}`, data)
}

/**
 * 工单字段配置删除
 * @param pids
 */
export function WorkOrderDelete(...ids: NumberLike[]) {
    return request.delete(`${ApiUri.SYSTEM}/supportFieldConfig/delete`, {params: {ids: ids.join(",")}})
}

/**
 * 认证名称下拉框
 * @param params
 */
export function CertificationNameSelect(params?: CertificationNameSelectParams) {
    return request.get(`${ApiUri.SYSTEM}/certificationName/select`, {params})
}

/**
 * 报修分类配置二级分类
 * @param params
 */
export function RepairCategorySelect(params?: RepairCategorySelectParams) {
    return request.get(`${ApiUri.COMPANY}/repairCategory/twoGrade`, {params})
}

/**
 * 费用说明配置新增
 * @param data
 */
export function costExplainSettingInsert(data: CostExplainSettingInsertParams) {
    return request.post<BaseResponse<any>>(`${ApiUri.SYSTEM}/costSetting/insert`, data);
}

/**
 * 费用说明配置修改
 * @param id
 * @param data
 */
export function costExplainSettingUpdate(id: NumberLike, data: CostExplainSettingInsertParams) {
    return request.put<BaseResponse<any>>(`${ApiUri.SYSTEM}/costSetting/update/${id}`, data);
}

/**
 * 费用说明配置删除
 * @param ids
 */
export function costExplainSettingDelete(...ids: NumberLike[]) {
    return request.delete<BaseResponse<any>>(`${ApiUri.SYSTEM}/costSetting/delete`, {params: {ids: ids.join(",")}})
}

/**
 * 费用说明配置分页查询
 * @param params
 */
export function costExplainSettingList(params: CostExplainSettingListParams) {
    return request.get<BasePageResponse<CostExplainSettingListItem>>(`${ApiUri.SYSTEM}/costSetting/getList`, {params});
}

/**
 * 费用说明配置查询详情
 * @param id
 */
export function costExplainSettingDetail(id: NumberLike) {
    return request.get<BaseResponse<CostExplainSettingListItem>>(`${ApiUri.SYSTEM}/costSetting/getDetail/${id}`);
}

/**
 * 自定义表单新增
 * @param data
 */
export function formAdd(data: FormAddParams) {
    return request.post(`${ApiUri.SYSTEM}/form/model/save`, data)
}

/**
 * 自定义表单发布
 * @param data
 */
export function formPublish(data: FormPublishParams) {
    return request.post(`${ApiUri.SYSTEM}/form/model/publish`, data)
}

/**
 * 自定义表单模型分页查询
 * @param params
 */
export function formModelList(params: FormModelListParams) {
    return request.get<BasePageResponse<FormModelListItem>>(`${ApiUri.SYSTEM}/form/model/page`, {params});
}

/**
 * 自定义表单模型删除
 * @param ids
 */
export function formModelListDelete(...ids: NumberLike[]) {
    return request.delete(`${ApiUri.SYSTEM}/form/model/delete`, {params: {ids: ids.join(",")}})
}

/**
 * 自定义表单模型详情
 * @param params
 */
export function formModelDetail(params: FormModelDetailParams) {
    return request.get<BaseResponse<FormModelListItem>>(`${ApiUri.SYSTEM}/form/model/info`, {params});
}

/**
 * 自定义表单定义分页查询
 * @param params
 */
export function formDefinitionList(params: FormModelListParams) {
    return request.get<BasePageResponse<FormModelListItem>>(`${ApiUri.SYSTEM}/form/definition/page`, {params});
}

/**
 * 自定义表单定义详情
 * @param params
 */
export function formDefinitionDetail(params: FormModelListParams) {
    return request.get<BaseResponse<any>>(`${ApiUri.SYSTEM}/form/definition/info`, {params});
}

/**
 * 原始字段列表
 * @param params
 */
export function formOriginFiledList(params: FormOriginFiledListParams) {
    return request.get<BaseResponse<OriginFiledListItem[]>>(`${ApiUri.SYSTEM}/form/origin/field/list`, {params});
}

export interface FormOriginFiledListParams {
    // 1000: 工单
    module: 1000;
    // 表单发布类型
    formType?: number;
}

export interface OriginFiledListItem {
    field: string;
    id: number;
    module: number;
    title: string;
    enable: boolean;
    required: boolean;
}

export interface FormAddParams {
    companyId: number;
    content: string;
    name: string;
    remark?: string;
    id?: number;
    originField?: string;
}

export interface FormPublishParams {
    companyId: number;
    formId: number;
    type: number;
    caseTypeId: number;
    // 发布类型 1-服务台 2-供应商 3-工程师
    // publishType?: number;
    // 原始字段JSON
    originField?: string;
}

export interface FormModelDetailParams {
    companyId?: number;
    id?: number;
    name?: string;
}

export type FormModelListParams = BaseQueryPageParam<{
    companyId?: number;
    name?: string;
    type?: number;
    id?: number;
    caseTypeId?: number;
}>

export interface FormModelListItem {
    companyId: number;
    companyName: string;
    content: string;
    originField: string;
    createId: number;
    createName: string;
    createTime: string;
    id: number;
    name: string;
    remark: string;
    updateId: number;
    updateName: string;
    updateTime: string;
    version: string;
    type: string;
}

/**
 * 字典
 */
export type DictParam = BaseQueryPageParam<{
    // 字典名称
    name?: string;
    // 父ID
    pid?: NumberLike;
    // 公司ID
    companyId?: NumberLike;
    // 描述
    remarks?: string;
}>

export type CostExplainSettingListParams = BaseQueryPageParam<{
    // 客户ID
    companyId: NumberLike;
}>

export interface CostExplainSettingInsertParams {
    remarks: string;
    companyId: NumberLike;
    companyName: string;
}

export interface CostExplainSettingListItem {
    // id
    id: NumberLike;
    // 费用说明
    remarks: string;
    // 客户id
    companyId: NumberLike;
    // 客户名称
    companyName: string;

    lastUpdateTime: string;
    createTime: string;

    lastUpdaterName: string;
    createrName: string;
}

export interface RepairCategorySelectParams {
    // 层级
    grade?: string;
    companyId?: string | number;
}

export interface OssPolicyResult {
    /**
     * @description  accessId
     */
    accessid: string;
    /**
     * @description  回调地址
     */
    callback: string;
    /**
     * @description  前端回调方法
     */
    callbackFun: string;
    /**
     * @description  文件路径
     */
    dir: string;
    /**
     * @description  到期时间
     * @format int64 参数格式
     */
    expire: number;
    /**
     * @description  访问地址
     */
    host: string;
    /**
     * @description  加密数据
     */
    policy: string;
    /**
     * @description  签名
     */
    signature: string;
    /**
     * @description  文件上传路径
     */
    uploadHost: string;
}

export interface CertificationNameSelectParams {
    companyId?: string;
    typeId?: string;
}

export interface WorkOrderSaveParams {
    companyName?: string;
    // 客户主键
    companyId?: NumberLike;
    // 作用位置(1报修字段配置 2结案字段配置)
    whereUse?: number;
    // 是否是原始字段(数据库表有该字段为原始字段 1是 0否)
    isOriginal?: number;
    // 字段英文code(是原始字段的时候只能使用数据库对应实体字段名)
    code?: string;
    // 字段中文名称
    name?: string;
    // 字段类型(1文本框 2下拉框 3图片)
    type?: number;
    // 当类型为下拉框的时候,该字段存下拉框值的字典pid(原始字段为下拉框，该字段无值 更具原始字段下拉框pid查询下拉框值即可)
    typeValId?: number;
    // 是否必填(1是必填 0不必填)
    isRequired?: number;
    // 是否显示(1显示 0不显示)
    isShow?: number;
    caseTypeId?: number;
    // 工单类型ID
    userSupportTypeId?: number;
}

export type WorkOrderListParams = BaseQueryPageParam<WorkOrderSaveParams & { id?: number; }>

export interface QueryDicdateSaveParams {
    name: string;
    pid: number;
    remarks?: string;
}

export interface QueryDeleteParams {
    /**
     * @description  主键id,多个逗号分隔
     */
    ids: string | number;
}

export type QueryListParams = BaseQueryPageParam<{
    pid?: number;
}>

export type QueryDeviceListParams = BaseQueryPageParam<{
    applyCount?: NumberLike;
    applyRemark: string;
    applyTime: string;
    applyTimeStart?: string;
    applyTimeEnd?: string;
    applyUserId: NumberLike;
    applyUserName: string;
    applyUserPhone: string;
    cancelTime: string;
    companyId?: NumberLike;
    companyName: string;
    consigneeAddr: string;
    consigneeUserId: NumberLike;
    consigneeUserName: string;
    consigneeUserPhone: string;
    consignerRemark: string;
    consignerTime: string;
    consignerUserId: NumberLike;
    consignerUserName: string;
    consignerUserPhone: string;
    courierName: string;
    courierNumber: string;
    deviceBrandId: NumberLike;
    deviceBrandName: string;
    deviceModelId: NumberLike;
    deviceModelName: string;
    deviceName: string;
    deviceNameId: NumberLike;
    id: NumberLike;
    status: string;
    storeBrandId: NumberLike;
    storeBrandName: string;
    storeNo: string;
    supportNo: string;
    supportTypeId: NumberLike;
    supportTypeName: string;
    serviceStationId: NumberLike;
    serviceStationName: string;
}>;

export type deviceAssistConfigParams = BaseQueryPageParam<{
    name: string;
    // 行业编码
    industryCode: NumberLike;
    // 客户编号
    companyNo: string;
    // 手机号码
    phone: string;
}>;

export interface ExcelErrorMsgListItem {
    // id
    id: NumberLike;
    // 记录编号
    recordNo: string;
    // 记录ID
    recordId: NumberLike;
    // 错误信息
    errorMsg: string;
    // 错误行
    rowExcel: NumberLike;
    // 错误列
    colExcel: NumberLike;
}

export interface ExcelRecordListItem {
    // id
    id: NumberLike;
    companyId: NumberLike;
    // 文件名称
    name: string;
    // 导入状态
    status: string;
    // 错误条数
    errorCount: NumberLike;
    // 成功条数
    successCount: NumberLike;
    // 导入条数
    totalCount: NumberLike;
    // 备份条数
    backCount: NumberLike;
    // 创建时间
    createTime: string;
    // 创建人
    createrId: NumberLike;
    // 创建人名称
    createrName: string;
    // 实体名称
    entityName: string;
}

export interface QueryExcelRecordParams {
    // 导入实体类型：字典：100900 非下拉选项，取出具体类型写死每个列表
    // 100901 => 用户:store
    // 100902 => 客户:company
    // 100903 => 客户人员:companyUser
    // 100904 => 服务站:serviceStation
    // 100905 => 工程师管理:engineer
    // 100906 => 工程师罚分:engineerPenalty
    // 100907 => 设备档案:EquipmentArchives
    // 100908 => 设备与客户管理导入:deviceCompany
    // 100909 => 报修分类:repairCategory
    entityName: string;
    // 字典：101100
    status: NumberLike;
    // 创建时间开始
    createStartTime: string;
    // 创建时间结束
    createEndTime: string;
}

export interface QueryExcelErrorMsgParams {
    recordId: NumberLike;
}

export interface DicdateItem {
    name: string;
    id: number;
    pid: number;
    dicitemVoList: DicitemVoListItem[];
}

export interface DicitemVoListItem {
    id: number;
    name: string;
    pid: number;
}

export interface AddressListItem {
    id: number;
    pid: number;
    title: string;
    level: number;
}

/**
 * 用户信息接口
 */
export interface AccountLoginInfo {
    /**
     * 用户名称
     */
    name: string;
    /**
     * 登录后的token
     */
    token: string;
    /**
     * 用户类型名称
     */
    accountTypeName: string;
    accountType: number;
    /**
     * 用户菜单列表
     */
    menuVoList: MenuVoListItem[];
    /**
     * 按钮权限列表
     */
    menuBtnVoList: menuBtnVoListItem[];

    roleId: number;

    companyId: string;
}

/**
 * 用户菜单接口
 */
export interface MenuVoListItem {
    /**
     * 菜单id
     */
    id: number;
    /**
     * 父菜单id
     */
    pid: number;
    /**
     * 菜单名称
     */
    name: string;
    /**
     * 图标
     */
    imgUrl: string;
    /**
     * 前端路由
     */
    beforeUrl: string;
    /**
     * 前端模块id
     */
    moduleId: number;
    /**
     * 排序
     */
    sort: number;
    /**
     * 子菜单
     */
    childMenuVoList: MenuVoListItem[];
    // 1目录 2页面 3按钮
    type: 1 | 2 | 3;
    // 是否在菜单中显示 1 显示 2 不显示
    isBeforeShow: 1 | 2;
}

export interface menuBtnVoListItem {
    id: NumberLike;
    name: string;
}

//辅材申请
export interface DeviceListItem {
    applyCount: number;
    applyRemark: string;
    applyTime: string;
    applyUserId: number;
    applyUserName: string;
    applyUserPhone: string;
    cancelTime: string;
    companyId: number;
    companyName: string;
    consigneeAddr: string;
    consigneeUserId: number;
    consigneeUserName: string;
    consigneeUserPhone: string;
    consignerRemark: string;
    consignerTime: string;
    consignerUserId: number;
    consignerUserName: string;
    consignerUserPhone: string;
    courierName: string;
    courierNumber: string;
    deviceBrandId: number;
    deviceBrandName: string;
    deviceModelId: number;
    deviceModelName: string;
    deviceName: string;
    deviceNameId: number;
    id: number;
    status: number;
    storeBrandId: number;
    storeBrandName: string;
    storeNo: string;
    supportNo: string;
    supportTypeId: number;
    supportTypeName: string;
    serviceStationId: number;
    serviceStationName: string;
    areaName: string;
}

export type QueryAreaSettingListParams = BaseQueryPageParam<{
    // 客户ID
    companyId: NumberLike;
    // 关键词
    keywords: string;
}>

