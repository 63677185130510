import {ComponentClass} from "react";
import {RouteConfig} from "../config";
import url from "url";
import {MenuVoListItem} from "../api/system";
import {PAGE_MODULE_LIST} from "../constants/PageModuleList";
import {MenuType} from "../constants/MenuType";

export class RouteNode {
  private _path: string;
  public get path(): string {
    if (this.parent && this.parent.path) {
      let parentPath = this.parent.path;
      if (!(/\/$/.test(parentPath))) {
        parentPath = parentPath + "/";
      }
      const path = this._path.replace(/^\//, "");
      return url.resolve(parentPath, path);
    }
    return this._path;
  };
  public name?: string;
  public icon?: string;
  public component?: () => Promise<{ default: ComponentClass<any, any> }>;
  public authority?: string[];
  public routes?: RouteNode[];
  private _redirect?: string;
  public get redirect(): string | undefined {
    if (!this._redirect) return undefined;
    if (this.parent && this.parent.path) {
      return `${this.path}${url.resolve(this.path, this._redirect)}`.replace(/\*/g, "").replace(/\/{2,}/g, "/")
    }
    return this._redirect;
  }

  constructor(option: RouteConfig, public parent?: RouteNode, public hideMenu?: boolean) {
    const {path, name, icon, component, authority, routes, redirect} = option;
    this._path = path;
    this.name = name;
    this.icon = icon;
    this.component = component;
    this.authority = authority;
    this._redirect = redirect;
    if (routes) {
      this.routes = routes.map(item => RouteNode.create(item, this, item.hideMenu));
    }

    if (option.moduleInfo) {
      option.moduleInfo.routeInfo = this;
    }

    if (!redirect && this.routes && this.routes.length > 0) {
      this._redirect = this.routes[0]._path;
    }
  }

  static create(option: RouteConfig, parent?: RouteNode, hideMenu: boolean = false): RouteNode {
    return new RouteNode(option, parent, hideMenu);
  }

  /**
   * 从后台菜单创建路由
   */
  static formMenuVoListItems(menus: MenuVoListItem[], parent: RouteNode): RouteNode[] {
    return formatMenu(menus).map(item => {
      return RouteNode.create(item, parent, item.hideMenu)
    });
  }
}

/**
 * 格式化路由
 * @param menus
 */
function formatMenu(menus: MenuVoListItem[]): RouteConfig[] {
  if (Array.isArray(menus)) {
    const _menus = menus.filter(item => item.type !== 3).map(item => {
      const menu: RouteConfig = {
        name: item.name,
        path: item.beforeUrl,
        icon: item.imgUrl,
        component: undefined,
        hideMenu: item.isBeforeShow === 2,
      };
      if (item.moduleId && item.type === MenuType.Page) {
        const fd = PAGE_MODULE_LIST.find(md => md.id === item.moduleId);
        if (fd) menu.component = fd.module;
        menu.moduleInfo = fd;
      } else if (item.type === MenuType.Directory) {
        menu.component = () => import("../layouts/BasicRouterLayout")
      }
      if (item.childMenuVoList) {
        menu.routes = formatMenu(item.childMenuVoList);
      }
      return menu;
    });
    _menus.push({
      path: "/404.html",
      component: () => import("../layouts/NotFoundLayout")
    }, {
      path: "*",
      redirect: "/404.html"
    })
    return _menus;
  }
  return  [];
}
